export const hero = {
  title: "Theme & amusement park POS management system",
  subTitle: "Amusements and Attractions ",
  subtext:
    "Let your guests focus on having an unforgettable time. With omnichannel POS, guests can order what they want, when they want, and how they want.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/amusements-and-attractions/demo",
  },
};

export const image = {
  imageName: "amusements-hero.png",
  mobileImageName: "amusements-hero-mobile.png",
  title: "AMUSEMENTS AND ATTRACTIONS",
};

export const highlightsSection = {
  title: "Shorten lines and increase sales with better ways to order",
  imageName: "amusements-highlights.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/amusements-and-attractions/demo",
  },
  highlights: [
    {
      title: "Order \n anywhere",
      subtext:
        "Fulfill guests' orders faster, so they can get back to the fun. Seamless mobile and online omnichannel ordering lets guests place and pick up orders from any vendor.",
    },
    {
      title: "Scan \n and go",
      subtext:
        "Eliminate gift shop crowds with scan and go technology that empowers guests to buy merchandise right from their mobile phone and easily verify their purchase.",
    },
    {
      title: "Self-serve \n kiosks",
      subtext:
        "Image-based workflows guide customers through the ordering experience and encourages them to add more items with embedded upsell and combo prompts.",
    },
  ],
};

export const highlightsHero = {
  image: {
    imageName: "amusements-highlights-2.png",
    mobileImageName: "amusements-highlights-2-mobile.png",
    title: "World-class technology and service",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Crucial tools for building peak season success",
  highlights: [
    {
      title: "Create VIP guest \n experiences",
      subtext:
        "Save time and streamline operations with powerful tools for passholder entitlement and guest discounts, along with guest intelligence tools to assess guest flow and revenue.",
    },
    {
      title: "Inventory tracking you \n can count on",
      subtext:
        "Reduce costly, time-intensive inventory counts by tracking all inventory in real time across the entire park or at specific revenue centers.",
    },
    {
      title: "Never miss out on an \n order again",
      subtext:
        "Keep your attraction running no matter what with a proprietary offline mode that automatically switches devices during connectivity issues.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/amusements-and-attractions/demo",
  },
};

export const partners = {
  title: "Partnered with the leaders in your industry",
  desktopImageName: "appetize-amusements-partners-desktop.png",
  mobileImageName: "appetize-amusements-partners-mobile.png",
};

export const heroQuote = {
  title: "Powering better business operations",
  quote: `“I love that I’m able to login to Connect from my mobile phone. I have the flexibility to manage the Museum and do everything from seeing how the day is performing to processing refunds from anywhere in the building or even at home.”`,
  personName: "Caitlin Davis",
  personPosition: "General Manager Cincinnati Museum Center",
  imageName: "amusements-hero-quote.png",
  mobileImageName: "amusements-hero-quote-mobile.png",
};

export const productsWithLinks = {
  title:
    "Built on a true omnichannel platform to support the evolution of amusement parks & attractions",
  subtext:
    "Modern, omnichannel ordering gives your guests what they want without getting in the way of their good time. Upgrade your amusement park POS systems to a centralized solution to increase revenue, elevate customer service, and turn your data into valuable business intelligence.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform your guest experience, provide valuable business insights, and deliver ROI to your park.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/amusements-and-attractions/demo",
  },
};
